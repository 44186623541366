import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import * as React from "react"
import { ManagePlayersQuery, ManagePlayersQueryVariables } from "../../../../__generated__/ManagePlayersQuery"
import { SetEntryRolesMutation, SetEntryRolesMutationVariables } from "../../../../__generated__/SetEntryRolesMutation"
import constants from "../../../../common/constants"
import { TPoolRouteProps } from "../../../../routes.d"
import Dropdown from "../../../components/Dropdown"
import ErrorView from "../../../components/ErrorView"
import PencilSvg from "../../../components/icons/Pencil"
import LoadingView from "../../../components/LoadingView"
import useControls from "../../../hooks/useControls"
import Analytics from "../../../utils/analytics"
import { edgeToNode, getQueryWithError } from "../../../utils/misc-utils"
import { palette } from "../../../utils/style-utils"
import { MANAGE_PLAYERS_QUERY, PLAYER_ENTRIES_QUERY, REMOVE_PLAYER_FROM_POOL_MUTATION, SET_ENTRY_ROLES_MUTATION } from "../../queries"
import Pagination from "../components/Pagination"
import { PoolPageSpacer, StandardPoolPageContainer } from "../components/PoolPage"
import { StickyTableTable, StickyTableXWrapper, TableHeaderRow, TableRow, TruncateText } from "../components/StickyTable"
import {
  Action,
  DropdownContent,
  ManagePlayersEditBtn,
  ManagePlayersEditCol,
  ManagePlayersEmailCol,
  ManagePlayersPlayerCol,
  ManagerIconSpan,
} from "../styles/ManagePlayers.styles"
import { emptyObject, emptyArray } from "../../../../common/misc-utils"
import MultipleEntriesManagePlayersWrapper from "./MultipleEntriesArea/ManagePlayers"
import { toast } from "@cbs-sports/sports-shared-client/build/cjs/components/Toast"
import { RemovePlayerFromPoolMutation, RemovePlayerFromPoolMutationVariables } from "../../../../__generated__/RemovePlayerFromPoolMutation"
import { useHistory } from "react-router-dom"
import { withoutDomain } from "../../../utils/url-utils"
import { PlayerEntriesQuery, PlayerEntriesQueryVariables } from "../../../../__generated__/PlayerEntriesQuery"
import { canUseDom } from "@cbs-sports/sports-shared-client/build/cjs/utils/dom-utils"

const toggleAccess = async (memberId, isManager, setEntryRolesMutation) => {
  const prefix = isManager ? "remove manager status" : "add manager status"
  Analytics.trackInteraction(`${prefix} - submit`)
  const result = await setEntryRolesMutation({
    variables: { memberId, roles: isManager ? [] : [constants.POOL_ROLES.MANAGER] },
    refetchQueries: ["ManagePlayersQuery", "PlayerEntriesQuery"],
    awaitRefetchQueries: true,
    fetchPolicy: "no-cache",
  }).then(
    () => Analytics.trackInteraction(`${prefix} - success`),
    () => Analytics.trackInteraction(`${prefix} - fail`),
  )
  // console.log(result)
  return result
}
const removePlayer = async (memberId, poolId, removePlayerMutation) => {
  Analytics.trackInteraction("remove entry - submit")
  const variables: RemovePlayerFromPoolMutationVariables = {
    memberId,
    poolId,
  }
  const result = await removePlayerMutation({
    variables,
    refetchQueries: ["ManagePlayersQuery"],
    awaitRefetchQueries: true,
    fetchPolicy: "no-cache",
  })
    .then((_res) => {
      Analytics.trackInteraction(`remove entry - success`)
      toast.snackbar("Player removed successfully")
    })
    .catch((err) => {
      Analytics.trackInteraction(`remove entry - fail`)
      console.error(err)
    })
  // console.log(result)
  return result
}

const ManagePlayersRightFootnote = () => {
  return (
    <h6>
      <ManagerIconSpan>{managerIcon}</ManagerIconSpan> = Pool Manager
    </h6>
  )
}

const addClickTracking = () => {
  Analytics.trackAction("player edit", "manage_players", "player_edit")
}

const ManagePlayersDataRow = ({
  player,
  setEntryRolesMutation,
  removePlayerMutation,
  toggleManagerMode,
  currentIsManager,
  poolId,
  isInComingSoon,
}) => {
  const isManager = player.roles.indexOf(constants.POOL_ROLES.MANAGER) > -1
  const { hideEmail, email, userInfo, isMine } = player
  const history = useHistory()

  const playerEntriesQueryCompleted = React.useCallback(
    (data) => {
      const nodes = data.pool.entries.edges.map(edgeToNode)
      const entriesCount = nodes.length
      if (entriesCount === 1) {
        //only one option. Skip modal and navigate to bracket page
        toggleManagerMode(true, () => {
          if (canUseDom) {
            window.location.href = withoutDomain(nodes[0].url)
          }
        })
      }
    },
    [history, poolId],
  )

  const [getPlayerEntries] = useLazyQuery<PlayerEntriesQuery, PlayerEntriesQueryVariables>(PLAYER_ENTRIES_QUERY, {
    onCompleted: playerEntriesQueryCompleted,
    fetchPolicy: "network-only",
  })

  const onMakePicksForPlayer = () => {
    getPlayerEntries({
      variables: {
        poolId: player.poolId,
        userId: player.userId,
      },
    })
  }

  return (
    <TableRow className="overflow-visible">
      <ManagePlayersPlayerCol highlight={isMine}>
        <TruncateText>{userInfo.preferredEntryName}</TruncateText>
      </ManagePlayersPlayerCol>
      <ManagePlayersEmailCol highlight={false}>
        <TruncateText>{!hideEmail ? email || "(no email)" : "********"} </TruncateText>
      </ManagePlayersEmailCol>
      <ManagePlayersEditCol highlight={false}>
        {currentIsManager && !isMine ? (
          <Dropdown
            disableHover={true}
            onClick={addClickTracking}
            toggler={(props) => (
              <ManagePlayersEditBtn {...props} as="button" type="button">
                <PencilSvg color={palette.uiBlue1} />
              </ManagePlayersEditBtn>
            )}
            dropdown={(props) => (
              <DropdownContent {...props}>
                <Action as="button" type="button" color="default" onClick={() => toggleAccess(player.id, isManager, setEntryRolesMutation)}>
                  {isManager ? "Revoke" : "Grant"} Manager Access
                </Action>
                {!isInComingSoon && (
                  <Action as="button" type="button" color="default" onClick={onMakePicksForPlayer}>
                    Edit Picks
                  </Action>
                )}
                <Action as="button" type="button" color="danger" onClick={() => removePlayer(player.id, poolId, removePlayerMutation)}>
                  Remove Player
                </Action>
              </DropdownContent>
            )}
            valueName={player.id}
          />
        ) : null}
        {(isManager && <ManagerIconSpan>M</ManagerIconSpan>) || null}
      </ManagePlayersEditCol>
    </TableRow>
  )
}
const managerIcon = "M"
const stylez = {
  overflow: "visible",
}

function ManagePlayersWrapper(props: TPoolRouteProps) {
  const { poolData } = props
  const { gameInstanceUid, poolId, detailedEntry, isInComingSoon, toggleManagerMode } = poolData
  const controls = useControls(props)
  const [removePlayerMutation, removePlayerMutationRes] = useMutation<RemovePlayerFromPoolMutation, RemovePlayerFromPoolMutationVariables>(
    REMOVE_PLAYER_FROM_POOL_MUTATION,
    {
      refetchQueries: ["ManagePlayersQuery"],
      awaitRefetchQueries: true,
    },
  )
  const [setEntryRolesMutation] = useMutation<SetEntryRolesMutation, SetEntryRolesMutationVariables>(SET_ENTRY_ROLES_MUTATION, {
    refetchQueries: ["ManagePlayersQuery"],
    awaitRefetchQueries: true,
  })
  const variables = {
    gameInstanceUid,
    poolId: poolId || "",
    first: controls.first,
    after: controls.after,
  }
  const skip = !variables.gameInstanceUid || !variables.poolId
  const managePlayersQuery = useQuery<ManagePlayersQuery, ManagePlayersQueryVariables>(MANAGE_PLAYERS_QUERY, { variables, skip })
  const withError = getQueryWithError([managePlayersQuery])
  if (withError) {
    return <ErrorView error={withError.error} refetch={withError.refetch} />
  }
  const isLoading =
    removePlayerMutationRes?.loading ||
    (!!managePlayersQuery && managePlayersQuery.hasOwnProperty("networkStatus") && managePlayersQuery.networkStatus < 3)
  const pageInfo = managePlayersQuery.data?.gameInstance.pool.players.pageInfo || emptyObject
  const players = ((!isLoading && managePlayersQuery.data?.gameInstance.pool.players.edges) || emptyArray).map(edgeToNode)
  const currentIsManager = detailedEntry && detailedEntry.roles && detailedEntry.roles.indexOf(constants.POOL_ROLES.MANAGER) > -1
  return (
    <StandardPoolPageContainer
      {...props}
      rightAction={<ManagePlayersRightFootnote />}
      analyticsFeature="players"
      analyticsSubfeature={``}
      analyticsTitle={`Players`}
    >
      <PoolPageSpacer />
      <StickyTableXWrapper style={stylez}>
        <StickyTableTable>
          <TableHeaderRow>
            <ManagePlayersPlayerCol highlight={false}>Player</ManagePlayersPlayerCol>
            <ManagePlayersEmailCol highlight={false}>Email</ManagePlayersEmailCol>
            <ManagePlayersEditCol highlight={false}>Role</ManagePlayersEditCol>
          </TableHeaderRow>
          {players.map((player) => {
            return (
              <ManagePlayersDataRow
                key={player.id}
                currentIsManager={currentIsManager}
                player={player}
                setEntryRolesMutation={setEntryRolesMutation}
                removePlayerMutation={removePlayerMutation}
                toggleManagerMode={toggleManagerMode}
                poolId={poolId}
                isInComingSoon={isInComingSoon}
              />
            )
          })}
        </StickyTableTable>
      </StickyTableXWrapper>
      {isLoading && <LoadingView />}
      <Pagination hasPreviousPage={!!controls.after} goToPage={controls.goToPage} isLoading={isLoading} {...pageInfo} />
    </StandardPoolPageContainer>
  )
}

function ManagePlayers(props: TPoolRouteProps) {
  const { poolData } = props
  const { areaHasMultipleEntriesPerUser } = poolData
  return (areaHasMultipleEntriesPerUser && <MultipleEntriesManagePlayersWrapper {...props} />) || <ManagePlayersWrapper {...props} />
}

export default ManagePlayers
