import React, { FC, useMemo, useCallback } from "react"
import { useMutation } from "@apollo/client"
import styled from "styled-components"
import { Formik, Form as FormikForm, Field, FormikValues, FormikErrors, FormikHelpers } from "formik"
import { fontSizes, fontWeight, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import bracketTheme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import Input from "@cbs-sports/sports-shared-client/build/cjs/components/Form/Input"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import { UPSERT_OFFLINE_ENTRY_MUTATION } from "../../queries"
import { UpsertOfflineEntry, UpsertOfflineEntryVariables } from "../../../../__generated__/UpsertOfflineEntry"
import { extractValidationError, FormErrors } from "../../../components/Form"

const px18 = pxToRem(18)

const Form = styled(FormikForm)`
  min-width: ${pxToRem(368)};
  padding: 1.5rem ${pxToRem(40)};
  flex: 1;
  display: flex;
  flex-direction: column;

  .form__title {
    font-size: ${fontSizes.large};
    font-weight: ${fontWeight.bold};
    display: block;
    color: ${bracketTheme.colors.overLight.white20};
    line-height: 1.5rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .form__row--input {
    margin-top: 0.5rem;
    margin-bottom: ${px18};
  }

  .form__row--actions {
    display: flex;
    justify-content: flex-end;
    margin-top: ${px18};
    * {
      margin-left: 1rem;
    }
  }
`

export interface IOfflineEntryFormProps {
  poolId: string
  onCancel?: () => void
  onComplete?: (data?: UpsertOfflineEntry | null) => void
}

interface IFormValues extends FormikValues {
  poolId: string
  name: string
}

const FORM_TITLE = "Add Offline Player"

const validate = (values: IFormValues) => {
  const errors: FormikErrors<IFormValues> = {}

  if (!values.name) {
    errors.name = "Player name is required"
  }

  return errors
}

const OfflineEntryForm: FC<IOfflineEntryFormProps> = ({ poolId, onCancel, onComplete }) => {
  const [upsertOfflineEntry] = useMutation<UpsertOfflineEntry, UpsertOfflineEntryVariables>(UPSERT_OFFLINE_ENTRY_MUTATION, {
    refetchQueries: ["BracketManagePlayersQuery"],
  })
  const initialValues = useMemo<IFormValues>(() => ({ poolId: poolId, name: "" }), [poolId])
  const initialErrors = useMemo<FormikErrors<IFormValues>>(() => {
    const errors: FormikErrors<IFormValues> = {
      name: "Player name is required",
    }
    return errors
  }, [])

  const handleSubmit = useCallback(
    (variables: IFormValues, actions: FormikHelpers<IFormValues>) => {
      return upsertOfflineEntry({ variables })
        .then((res) => {
          onComplete?.(res.data)
        })
        .catch((err) => {
          const apiErrors = extractValidationError(err)
          actions.setErrors(apiErrors.errors)
        })
    },
    [upsertOfflineEntry, onComplete],
  )

  const handleCancel = useCallback(() => {
    onCancel?.()
  }, [onCancel])

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate} initialErrors={initialErrors}>
      {(formik) => (
        <Form>
          <div className="form__title">{FORM_TITLE}</div>
          <div className="form__row--input">
            <Field as={Input} name="name" label="Player Name" autoComplete="off" />
          </div>
          <FormErrors {...formik} />
          <div className="form__row--actions">
            <Button variant="secondary" type="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="primary" withLoading type="submit" disabled={!formik.isValid} loading={formik.isSubmitting}>
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default OfflineEntryForm
