import React from "react"
import { TPoolRouteProps } from "../../../../routes.d"
import MultipleEntriesPoolInviteMailCenterModal from "../containers/MultipleEntriesPoolInviteMailCenter"

interface IProps extends TPoolRouteProps {
  isOpen: boolean
  onClose: () => void
  onInviteSent: () => void
}

const EmailPoolModal: React.FC<IProps> = (props) => {
  return (
    <MultipleEntriesPoolInviteMailCenterModal
      {...props}
      defaultSubject={"Message From Your Bracket Pool Manager"}
      onNotificationSent={props.onInviteSent}
      variant={"email-pool-notification"}
    />
  )
}

export default React.memo(EmailPoolModal)
