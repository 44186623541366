import React from "react"
import styled, { css } from "styled-components"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import { pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { PlayerBracketsQuery, PlayerBracketsQuery_pool_entries_edges_node } from "../../../../../__generated__/PlayerBracketsQuery"
import { RadioDualLineEntryRow } from "../../containers/EntryRow"
import GenericEntryModal, { IGenericEntryModalProps, noop } from "./GenericEntryModal"
import { IPoolData } from "../../../../../routes.d"
import { canUseDom } from "../../../../utils/misc-utils"

export type PlayerBracketType = PlayerBracketsQuery["pool"]["entries"]["edges"][number]["node"]
const DEFAULT_MODAL_TITLE = "Select Bracket"
const ACTION_PROPS = { dense: true }
const px14 = pxToRem(14)
const px80 = pxToRem(80)
const px488 = pxToRem(488)
const importModalText = css`
  font-size: ${px14};
  line-height: ${px14};
  letter-spacing: -0.1px;
  color: ${theme.colors.overLight.white20};
  margin-bottom: 0.75rem;
`

const EditPlayerBracketRoot = styled.div`
  width: calc(${px488} - ${px80});

  .edit-modal__text {
    ${importModalText}
  }

  .edit-modal__text--bold {
    ${importModalText}
    font-weight: ${theme.fonts.weights.bold};
  }

  .edit-modal__bracket-list {
    padding: 1rem;
    box-sizing: border-box;
    max-height: ${pxToRem(169)};
    border: solid 1px ${theme.colors.overLight.white90};
    border-radius: 0.25rem;
    overflow: hidden auto;
  }

  .edit-modal__bracket-list-item {
    margin-top: 1rem;
    margin-bottom: 1rem;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .label-content {
      margin-left: 2rem;
      min-width: 0;
      color: orange;
    }
  }
`
export interface EditPlayerBracketModalProps extends IGenericEntryModalProps {
  close: () => void
  availableBrackets: Array<PlayerBracketType>
  toggleManagerMode: IPoolData["toggleManagerMode"]
}

const EditPlayerBracketModal: React.VFC<EditPlayerBracketModalProps> = ({
  title = DEFAULT_MODAL_TITLE,
  close = noop,
  availableBrackets,
  toggleManagerMode,
  ...props
}) => {
  const [selectedEntry, setSelectedEntry] = React.useState<PlayerBracketsQuery_pool_entries_edges_node | null>(null)
  React.useEffect(() => {
    if (availableBrackets.length > 0) {
      setSelectedEntry(availableBrackets[0]) //mark the first entry as selected whenever the modal is opened
    }
  }, [availableBrackets])
  const handleNavigation = () => {
    if (!selectedEntry) {
      return
    }
    toggleManagerMode(true, () => {
      if (canUseDom) {
        window.location.href = selectedEntry.url
      }
    })
  }

  const actions = (
    <>
      <Button variant="secondary" onClick={close}>
        Cancel
      </Button>
      <Button variant="primary" disabled={!selectedEntry} onClick={handleNavigation}>
        Continue
      </Button>
    </>
  )

  const importEntryItems = availableBrackets.map((entry) => (
    <div className="edit-modal__bracket-list-item" key={entry.id}>
      <RadioDualLineEntryRow
        name={`import-bracket-${entry.id}`}
        entry={entry}
        isChecked={entry === selectedEntry}
        onChange={() => setSelectedEntry(entry)}
      />
    </div>
  ))

  return (
    <GenericEntryModal
      title={title}
      afterClose={close}
      onBackgroundClick={noop}
      onEscapeKeydown={noop}
      actions={actions}
      actionProps={ACTION_PROPS}
      {...props}
    >
      <EditPlayerBracketRoot>
        <div className="edit-modal__text">Select the bracket you would like to edit/view:</div>
        <div className="edit-modal__bracket-list">{importEntryItems}</div>
      </EditPlayerBracketRoot>
    </GenericEntryModal>
  )
}

export default EditPlayerBracketModal
