import { fontFamily } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import styled from "styled-components/macro"
import { OpacityBtn } from "../../../components/Button"
import { fontWeight, palette, pxToRem, zIndexes } from "../../../utils/style-utils"
import { IHighlightable, leaderboardBreakpointW2, leaderboardBreakpointWpx } from "../components/StickyTable"

export const ManagePlayersPlayerCol = styled.div<IHighlightable>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  color: #000;
  font-weight: ${fontWeight.bold};
  padding-left: ${pxToRem(16)};
  padding-right: ${pxToRem(16)};
  margin-right: ${pxToRem(8)};
  justify-content: left;
  user-select: text;
  flex: 0 0 20vw;
  min-width: ${pxToRem(120)};
  border-left: ${pxToRem(4)} solid ${({ highlight }) => (highlight ? `${palette.uiNavBlue1}` : `rgba(0,0,0,0)`)};
  white-space: nowrap;
  line-height: 1rem;
  @media (max-width: ${leaderboardBreakpointWpx}) {
    justify-content: flex-start;
    padding-left: ${pxToRem(16)};
    font-size: ${pxToRem(13)};
    font-weight: ${fontWeight.regular};
    padding-left: ${pxToRem(8)};
    padding-right: 0;
  }
  @media (max-width: ${pxToRem(leaderboardBreakpointW2)}) {
    font-size: ${pxToRem(13)};
  }
`

export const ManagePlayersEmailCol = styled(ManagePlayersPlayerCol)`
  justify-content: flex-start;
  flex: 1 1 20vw;
  user-select: text;
`

export const ManagePlayersEditCol = styled(ManagePlayersPlayerCol)`
  padding: 0;
  justify-content: center !important;
  overflow: visible;
  flex: 0 0 ${pxToRem(90)};
  min-width: unset;
`

export const ManagePlayersHeaderCol = styled.div`
  font-size: ${pxToRem(16)} !important;
  text-transform: uppercase;
  padding: ${pxToRem(16)};
  font-weight: ${fontWeight.semiBold};
`
interface ActionProp {
  color: "default" | "danger"
}
export const Action = styled.button<ActionProp>`
  display: block;
  color: ${({ color }) => (color === "danger" ? "red" : "#232323;")};
  font-weight: ${fontWeight.bold};
  font-size: ${pxToRem(18)};
  text-align: center;
  padding: ${pxToRem(16)} ${pxToRem(22)};
  width: ${pxToRem(280)};
  max-width: 100%;
  transition: all 0.2s ease-out;
  @media (hover: hover) {
    &:hover:not(:active) {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.15);
  }
`
export const DropdownContent = styled.div`
  top: -100%;
  right: 75%;
  background-color: #fff;
  box-shadow: 0px ${pxToRem(16)} ${pxToRem(24)} ${pxToRem(12)} rgba(0, 0, 0, 0.2);
  z-index: ${zIndexes.siteNavDropdown};
`

export const ManagePlayersEditBtn = styled(OpacityBtn)`
  padding: ${pxToRem(8)};
  &:disabled {
    transition: none;
    opacity: 0;
  }
  & svg {
    width: ${pxToRem(32)};
    height: ${pxToRem(32)};
    fill: ${palette.uiBlue1};
  }
`

export const ManagerIconSpan = styled.span`
  color: #a02522;
  font-size: ${pxToRem(14)};
  font-weight: ${fontWeight.bold};
`
export const MultipleEntriesManagePlayerContent = styled.div`
  display: flex;
  flex-direction: column;
  background: ${palette.white};
  border: 1px solid ${palette.gray90};
  box-sizing: border-box;
  border-top: none;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  overflow: hidden;
`
export const MultipleEntriesManagePlayerHeader = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-top: none;
  padding: 0 1.5rem;

  & > .player-header-title {
    font-family: ${fontFamily.base};
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0px;
    text-align: left;
    color: ${palette.gray20};
    padding: 1.25rem 0;
    text-transform: uppercase;
  }

  & > .player-header-subtitle {
    font-family: ${fontFamily.base};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: -0.1px;
    text-align: left;
    color: ${palette.gray40};
  }
  & > .player-header-actions {
    display: flex;
    flex-direction: row-reverse;
    box-sizing: border-box;
    border-top: none;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;

    & > .player-header-actions-search {
      display: flex;
      box-sizing: border-box;
    }
    & > .action-buttons {
      display: flex;
      & > .email-pool-cta {
        margin: 0 1rem;
      }
    }
  }
`

export const StickyTableTable = styled.div``

export const MultipleEntriesManagePlayerStickyTable = styled.div`
  flex: none;
  min-width: 100%;

  & > .multiple-entries-players-section-tile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    color: ${palette.gray20};

    & > .section-title {
      font-family: ${fontFamily.base};
      font-size: 1rem;
      font-style: normal;
      font-weight: ${fontWeight.bold};
      line-height: 1;
      letter-spacing: -0.1px;
      text-align: left;
    }
    & > .pagination-info {
      font-family: ${fontFamily.base};
      font-style: normal;
      font-weight: ${fontWeight.regular};
      letter-spacing: -0.1px;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
    }
  }

  & > .multiple-entries-players-table-header {
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;

    & > span {
      font-family: ${fontFamily.condensed};
      font-size: 0.75rem;
      font-style: normal;
      font-weight: ${fontWeight.medium};
      line-height: 1;
      letter-spacing: 1.25px;
      text-align: left;
      color: ${palette.gray50};
      text-transform: uppercase;
    }
    & > .player-name {
      flex: 2;
      padding-right: 1rem;
    }
    & > .player-email {
      flex: 4;
    }
    & > .table-header-actions {
      flex: 3;

      & > .player-is-manager {
        width: 4rem;
        text-align: right;
        display: block;
      }
    }
  }
`
export const MultipleEntriesPlayerRowWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  border-top: 1px solid ${palette.gray90};
  background-color: ${palette.white};
  min-width: 100%;
  width: fit-content;
  position: relative;
  overflow: visible;
  padding: 0 1.5rem;
  height: 3rem;
  box-sizing: border-box;
  font-family: ${fontFamily.base};
  font-size: 14px;
  font-style: normal;
  line-height: 1rem;
  letter-spacing: -0.1px;
  font-weight: ${fontWeight.regular};
  text-align: left;
  align-items: center;
  color: ${palette.gray20};

  & > .player-name {
    flex: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    padding-right: 1rem;
  }
  & > .player-email {
    font-weight: ${fontWeight.bold};
    flex: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & > .actions-on-player {
    font-weight: 400;
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .player-is-manager {
      width: 4rem;
      text-align: right;
    }
  }

  &.is-my-entry:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0.25rem;
    background-color: ${palette.lightBlue3};
    border-radius: 0 0.25rem 0.25rem 0;
  }
`
